import React from 'react';
import { motion } from 'framer-motion'; 
import TsTitle from '../assets/SectionsTitle/Technicals Skills.svg';
import EduTitle from '../assets/SectionsTitle/Education.svg';
import { dataSkills } from '../data';
import '../styles/Skills.css';

const containerTechSkills = {
    hidden: { opacity: 0 },
    visible: i => ({
      opacity: 1,
      transition: {
        delay: i,
        staggerChildren: 0.5
      }
    })
  };
  
const itemTechSkills = {
    hidden: { y: 30, opacity: 0 },
    visible: i => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: i,
      }
    })
};

const containerEduSkills = {
    hidden: { opacity: 1 },
    visible: i => ({
      opacity: 1,
      transition: {
        delay: i,
        staggerChildren: 0.8
      }
    })
};

const itemEduSkills = {
    hidden: { y: 30, opacity: 0 },
    visible: i => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: i,
      }
    })
};
const Skills = () => {
    return (
        <section className="Skills" id='skills'>
            <div className="technical-container">
                <div className="technical_skills-title">
                    <motion.img src={TsTitle} alt="Technical Skills Title" 
                        initial={{y: 30, opacity: 0}}
                        whileInView={{y: 0, opacity: 1}}
                        transition={{duration: 1}}
                        viewport={{ once: true, amount: 'some' }}
                    />
                </div>
                <div className="technical_skills-content">
                    <motion.ul className="technical-skills__list"
                        custom={0}
                        variants={containerTechSkills}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 'some' }}
                    >
                            {
                                dataSkills.map((data, key) => {
                                    return  <motion.li key={key}
                                                custom={1}
                                                variants={itemTechSkills}
                                                viewport={{ once: true, amount: 'some' }}
                                            >
                                                <div className="technical-skills__item">
                                                    <div className="skill-description">
                                                        <p className="skill-name">{data.title}</p>
                                                        <p className="skill-progress">{data.level}</p>
                                                    </div>
                                                    <div className="progress-bar">
                                                        <motion.div style={{width: data.level}}
                                                            initial={{width: 0}}
                                                            whileInView={{width: data.level}}
                                                            transition={{duration: 1, delay: 2.5}}
                                                            viewport={{ once: true, amount: 'some' }}
                                                        >
                                                        </motion.div>
                                                    </div>
                                                </div>
                                            </motion.li>
                                })
                            }
                        </motion.ul>
                </div>
            </div>
            <div className="education-container">
                <div className="education-title">
                    <motion.img src={EduTitle} alt="Education Title" 
                        initial={{y: 30, opacity: 0}}
                        whileInView={{y: 0, opacity: 1}}
                        transition={{duration: 1.5}}
                        viewport={{ once: true, amount: 'some' }}
                    />
                </div>
                <motion.ul className="education-description"
                    custom={0}
                    variants={containerEduSkills}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 'some' }}
                >
                    <motion.li className="card card1"
                        custom={1}
                        variants={itemEduSkills}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 'some' }}
                    >
                        <p className="card-title">
                            +20 courses in <a href="https://platzi.com/p/Benyamin22/">Platzi</a>
                        </p>
                        <div className="card-description">
                            <p>2019-2022</p>
                            <p>
                                I totally support and believe in self-taught education, 
                                that's why I use my time to learn through online courses 
                                and keep myself updated with new technologies.
                            </p>
                        </div>
                        <motion.button className="button-vp"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <a href="https://platzi.com/p/Benyamin22/">Visit Profile</a>
                        </motion.button>
                    </motion.li>
                    <motion.li className="card card2"
                        custom={1}
                        variants={itemEduSkills}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 'some' }}
                    >
                        <p className="card-title">
                            High School <p>Buen Pastor</p>
                        </p>
                        <div className="card-description">
                            <p>2018</p>
                            <p>
                                Certificate of Studies in Sciences and letters
                            </p>
                        </div>
                    </motion.li>
                </motion.ul>
            </div>
        </section>
    );
}

export default Skills;
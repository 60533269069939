import PhoneIcon from './assets/Icons/Telefono.svg';
import EmailIcon from './assets/Icons/Correo.svg';
import AddressIcon from './assets/Icons/Ubicación.svg';
import JS from './assets/Icons/JavaScript.svg';
import HTML from './assets/Icons/HTML.svg';
import CSS from './assets/Icons/CSS.svg';
import REACT from './assets/Icons/React.svg';
import REDUX from './assets/Icons/Redux.svg';
import GIT from './assets/Icons/Git.svg';
import CLOUD from './assets/Icons/Cloudflare.svg';
import GITHUB from './assets/Icons/GitHub.svg';

export const menuOptions = [
    {
        id: "home",
        name: "Home",
    },
    {
        id: "about",
        name: "About",
    },
    {
        id: "skills",
        name: "Skills",
    },
    {
        id: "projects",
        name: "Projects",
    },
    {
        id: "contact",
        name: "Contact",
    },
];

export const dataContact = [
    {
        icon: PhoneIcon,
        title: "Phone",
        description: "+507 6923-6211",
        href: "callto:"
    },
    {
        icon: EmailIcon,
        title: "Email",
        description: "m13nav86@gmail.com",
        href: "mailto:"
    },
    {
        icon: AddressIcon,
        title: "Address",
        description: "Panamá",
        /* href: "" */
    },
];

export const aboutSkills = [
    {
        icon: JS,
        className: "about-skills__list",
        id: "javascript",
    },
    {
        icon: HTML,
        className: "about-skills__list",
        id: "html",
    },
    {
        icon: CSS,
        className: "about-skills__list",
        id: "css",
    },
    {
        icon: REACT,
        className: "about-skills__list",
        id: "react",
    },
    {
        icon: REDUX,
        className: "about-skills__list",
        id: "redux",
    },
    {
        icon: GIT,
        className: "about-skills__list",
        id: "git",
    },
    {
        icon: CLOUD,
        className: "about-skills__list",
        id: "cloudflare",
    },
    {
        icon: GITHUB,
        className: "about-skills__list",
        id: "github",
    },
];

export const dataSkills = [
    {
        title: "HTML",
        level: '80%'
    },
    {
        title: "CSS",
        level: '70%'
    },
    {
        title: "JavaScript",
        level: '60%'
    },
    {
        title: "React",
        level: '75%'
    }
];


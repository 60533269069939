import React from 'react';
import { motion } from 'framer-motion';
import AboutTitle from '../assets/SectionsTitle/About Me.svg';
import { aboutSkills } from '../data';
import '../styles/About.css';

const containerAbout = {
    hidden: { opacity: 1 },
    visible: i => ({
      opacity: 1,
      transition: {
        delay: i,
        staggerChildren: 0.3
      }
    })
};
  
const textDescription = {
    hidden: { y: 30, opacity: 0 },
    visible: i => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: i,
      }
    })
};
const containerSkills = {
    hidden: { opacity: 0 },
    visible: i => ({
      opacity: 1,
      transition: {
        delay: i,
        staggerChildren: 0.2
      }
    })
};
  
const skillsItems = {
    hidden: { y: 30, opacity: 0 },
    visible: i => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: i,
      }
    })
};

const button = {
    hidden: { y: 30, opacity: 0 },
    visible: i => ({
        y: 0, 
        opacity: 1,
        transition: {
            duration: 1,
            delay: i
        }
    })
}
const About = () => {
    return (
        <section className="About" id='about'>
            <div className="about-title">
                <motion.img src={AboutTitle} alt="About Title" 
                    initial={{y:30, opacity: 0}}
                    whileInView={{y: 0, opacity: 1}}
                    transition={{duration: 1}}
                    viewport={{ once: true, amount: 'some' }}
                />
            </div>
            <motion.div className="about-description"
                custom={0}
                variants={containerAbout}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 'some' }}
            >
                <motion.p className="about-bio"
                    custom={1.2}
                    variants={textDescription}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 'some' }}
                >
                    Hello!, I'm a front-end developer from Panama. 
                    My name is Brayan. I'm an enthusiastic lover 
                    of design and technology. With knowledge of 
                    web project development and providing creativity 
                    to the community.
                </motion.p>
                <motion.ul className="about-skills"
                    custom={0.5}
                    variants={containerSkills}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 'some' }}    
                >
                    {
                        aboutSkills.map((data, key) => {
                            return(
                                <motion.li className={data.className} key={key}
                                    custom={1}
                                    variants={skillsItems}
                                >
                                    <img id={data.id} src={data.icon} alt={data.id} />
                                </motion.li>
                            );
                        })
                    }
                </motion.ul>
                <motion.button className="download-cv"
                    custom={1.5}
                    variants={button}
                    initial="hidden"
                    whileInView="visible"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    viewport={{ once: true, amount: 'some' }}
                >
                    <a  href="https://drive.google.com/file/d/1M9vSID52XkxiK2XVCEt9uWhZDc19p4up/view"
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        Download CV
                    </a>
                </motion.button>
            </motion.div>
        </section>
    );
}

export default About;
import React, {useState} from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import SwipeableViews from 'react-swipeable-views';
import Batatabit from '../assets/images/Batatabit.webp';
import Sneakers from '../assets/images/Sneakers.webp';
import YardSale from '../assets/images/YardSaleShop.webp';
import Kimnhealth from '../assets/images/Kimnhealth.webp';
import CampNR from '../assets/images/CampNuevaRuta.webp';
import '../styles/Carousel.css';

const images = [
    {
        label: 'Batatabit',
        imgPath: Batatabit,
        description: "Website development with responsive design",
        href: "https://batatabit.pages.dev/"
    },
    {
        label: 'Sneakers',
        imgPath: Sneakers,
        description: "E-commerce store landing page development in React.js",
        href: "https://ecommerce-page-main.pages.dev/"
    },
    {
        label: 'YardSale Shop',
        imgPath: YardSale,
        description: "E-commerce store development with React.js",
        href: "https://yardsale-shop.pages.dev/"
    },
    {
        label: 'Kimnhealth',
        imgPath: Kimnhealth,
        description: `Nutrition website developed in React.js,
          Implementation of Styled Components and react-router-dom.`,
        href: "https://kimnhealth.pages.dev/"
    },
    {
      label: 'Campamento Nueva Ruta',
      imgPath: CampNR,
      description: `Camp website developed in React.js,
        Implementation of framer motion, material ui and react-router-dom.`,
      href: "https://camp-jadzo-nr.pages.dev/"
  },
];

const item = {
  hidden : { y: 30,  opacity: 0 },
  visible: i => ({
      y: 0,  
      opacity: 1,
      transition: {
          duration: 1,
          delay: i
        }
  })
}

function Carousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;
  const handleNext = () => {
    activeStep === (maxSteps - 1)
    ? setActiveStep(0)
    : setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    activeStep === 0
    ? setActiveStep(maxSteps - 1)
    : setActiveStep(activeStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <motion.div className="carousel-container__item"
      custom={0}
      variants={item}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 'some' }}
    >
      <Box sx={{ 
        maxWidth: 700, 
        flexGrow: 1, 
        backgroundColor: 'transparent',
        padding: "20px 0",  
        }}>
        <Paper
            square
            elevation={0}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 20,
                pl: 2,
                backgroundColor: '#222831',
                borderTopRightRadius: '15px',
                borderTopLeftRadius: '15px'
            }}
        >
        </Paper>
        <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
        >
            {images.map((step, index) => (
              <div key={step.label} className='carousel-item'>
                  {Math.abs(activeStep - index) <= 2 ? (
                      <img className='carousel-image' src={step.imgPath} alt={step.label} />
                  ) : null}
                  <div className="project-label">
                      <h1 className='carousel-title'>
                        {images[activeStep].label}
                      </h1>
                      <p className="projects-description">
                        {images[activeStep].description}
                      </p>
                      <motion.button className="view-live"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <a href={images[activeStep].href}
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          View Live
                        </a>
                      </motion.button>
                  </div>
              </div>
            ))}
        </SwipeableViews>
        <MobileStepper
          variant='dots' 
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              <p className='button-next'>{"Next"}</p>
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              <p className='button-back'>{"Back"}</p>
            </Button>
          }
          sx={{
              fontSize: '1.6rem',
              color: 'white',
              backgroundColor: '#222831',
              borderBottomRightRadius: '15px',
              borderBottomLeftRadius: '15px',
              "& .MuiMobileStepper-dot": {
                backgroundColor: "var(--soft-dark)"
              },
              "& .MuiMobileStepper-dotActive": {
                backgroundColor: "var(--cyan)"
              }
          }}
        />
      </Box>
    </motion.div>
  );
}

export default Carousel;


import React from 'react';
import Loader from '../components/Loader';
import Header from '../components/Header';

const Layout = ({children}) => {
    
    return (
        <div className={`Layout`}>
            <Loader />
            <Header />
            {children}
        </div>
    );
}

export default Layout;
import React from 'react';
import {AppContextProvider } from './context/AppContextProvider';
import Layout from './container/Layout';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Proyects from './components/Proyects';
import Contact from './components/Contact';


const App = () => {
    return (
        <AppContextProvider>
            <div>
                <Layout>
                    <Home />
                    <About />
                    <Skills />
                    <Proyects />
                    <Contact />
                </Layout>
            </div>
        </AppContextProvider>
    );
}

export default App;
import React from 'react';
import { motion } from 'framer-motion';
import ProjectTitle from '../assets/SectionsTitle/Proyects.svg';
import Carousel from './Carousel';
import '../styles/Projects.css';

const container = {
    hidden : { opacity: 1 },
    visible: { 
        opacity: 1,
        transition: {
            /* delay: i, */
            duration: 1,
            staggerChildren: 0.5
          }
    } 
}

const item = {
    hidden : { y: 30,  opacity: 0 },
    visible: i => ({
        y: 0,  
        opacity: 1,
        transition: {
            duration: 1,
            delay: i
          }
    })
}

const Proyects = () => {
    return (
        <section className="Projects" id='projects'>
            <motion.div className="projects-container"
                variants={container}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 'some' }}
            >
                <div className="projects-title">
                    <motion.img src={ProjectTitle} alt="Projects Title" 
                        custom={0}
                        variants={item}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 'some' }}
                    />
                </div>
                <div className="projects-carousel">
                    <div className="carousel-container">
                        <Carousel />
                    </div>
                </div>
            </motion.div>
        </section>
    );
}

export default Proyects;
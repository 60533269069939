import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import logoBnCode from '../assets/Icons/Logo BN.svg';
import menuIcon from '../assets/Icons/Menu.svg';
import { AppContext } from '../context/AppContextProvider';
import { menuOptions } from '../data';
import '../styles/Header.css';

const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
};

const sideBar = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
};

const Header = () => {
    const { menu, setMenu, changeColor, setChangeColor } = useContext(AppContext);
    const handleMenu = () => {
        setMenu(!menu);
    }

    const changeNavbarColor = () =>{
        if(window.scrollY >= 70){
          setChangeColor(true);
        }
        else{
          setChangeColor(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);

    return (
        <header className={changeColor ? "colorchange" : ""}> 
            <img src={logoBnCode} alt="logo" className='logoHeader' />
            <span className="menu-btnOpen">
                <img 
                    src={menuIcon} 
                    alt="menu icon"
                    onClick={handleMenu} 
                />
            </span>
            <nav className={`nav-bar ${menu && 'nav-bar__active'}`}>
                <motion.ul className="sections-list" variants={variants}>
                    {menuOptions.map((section, key) => {
                        return(
                            <motion.li key={key}
                                variants={sideBar}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                viewport={{once: true, amount: 'some'}}
                            >
                                <Link 
                                    activeClass='active' 
                                    to={section.id} 
                                    spy={true} 
                                    smooth={true} 
                                    offset={0} 
                                    duration={400}
                                    onClick={() => {handleMenu()}}
                                >
                                    {section.name}
                                </Link>
                            </motion.li>
                        );
                    })}
                </motion.ul>
            </nav>
        </header>
    );
}

export default Header;
import React from 'react';
import { motion } from 'framer-motion';
import { dataContact } from '../data';
import '../styles/Home.css';

const container = {
    hidden: { opacity: 0 },
    visible: i => ({
      opacity: 1,
      transition: {
        delay: i,
        /* delayChildren: 1.8,  */ /* Original 1.8 */
        staggerChildren: 0.3  /* Original 1 */
      }
    })
  };
  
  const item = {
    hidden: { y: 30, opacity: 0 },
    visible: i => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: i,
      }
    })
  };
  
const Home = () => {
    return (
        <section className="Home" id='home'>
            <motion.div className="presentation"
                custom={0}
                variants={container}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 'some' }}
            >
                <motion.h1 className="greetings"
                    custom={1}
                    variants={item}
                >
                    Hell<span>o</span>!
                </motion.h1>
                <motion.p className="name"
                    custom={1}
                    variants={item}
                >
                    I'm Brayan Navarro
                </motion.p>
                <motion.p className="career"
                    custom={1}
                    variants={item}
                >
                    Frontend Developer
                </motion.p>
            </motion.div>
            <div className="information">
                <motion.ul className="information-list"
                    custom={1.4}
                    variants={container}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 'some' }}
                >
                    {dataContact.map((data, index) => {
                        return( 
                            <motion.li key={index} className={data.title}
                                custom={1.8}
                                variants={item}
                            >
                                <img src={data.icon} alt={data.title} />
                                <a href={data.href}>{data.description}</a>
                            </motion.li>
                        );
                    })}
                </motion.ul>
            </div>
        </section>
    );
}

export default Home;
import React, { useState } from 'react';

const AppContext = React.createContext();

function AppContextProvider({ children }) {
    const [menu, setMenu] = useState(false);
    const [changeColor, setChangeColor] = useState(false); 
    return(
        <AppContext.Provider value={{
            menu,
            setMenu,
            changeColor,
            setChangeColor,
        }}>
            {children}
        </AppContext.Provider>
    );
}

export {AppContextProvider, AppContext};